<template>
    <div class="main-container">
      <h5 class="pageTitle">관리자 수정/삭제</h5>
      <form class="form-container" @submit.prevent="updateAdmin">
        <!-- 관리자 이름 -->
        <div class="form-group">
          <label>관리자 이름</label>
          <input
            class="input-box"
            type="text"
            v-model="formData.name"
            
          />
        </div>
        <!-- 관리자 아이디 -->
        <div class="form-group">
          <label>관리자 아이디</label>
          <input
            class="input-box"
            type="text"
            v-model="formData.id"
            @blur="validateDuplicateID"           
          />
          <p v-if="errors.id" class="error">{{ errors.id }}</p>
        </div>
        <!-- 비밀번호 -->
        <div class="form-group">
          <label>비밀번호</label>
          <input
            class="input-box"
            type="password"
            v-model="formData.password"
            @input="validatePasswords"
          />
          <p v-if="errors.password" class="error">{{ errors.password }}</p>
        </div>

        <div class="form-group">
          <label>비밀번호(확인)</label>
          <input
            class="input-box"
            type="password"
            v-model="formData.passwordConfirm"
            @input="validatePasswords"
          />
          <p v-if="errors.passwordConfirm" class="error">{{ errors.passwordConfirm }}</p>
        </div>
        <!-- 전화번호 -->
        <div class="form-group">
          <label>전화번호</label>
          <input
            class="input-box"
            type="text"
            :value="formData.mobile_no"             
            @input="handleMobileNoInput"
            @blur="validateMobileNo"
          />
          <p v-if="errors.mobile_no" class="error">{{ errors.mobile_no }}</p>
        </div>
        <!-- 관리자 구분 -->
        <div class="form-group">
          <label>관리자 구분</label>
          <input
            class="input-box"
            type="text"
            :value="translatedAdminType"
            disabled
          />
        </div>
        <!-- SMS 수신 여부 -->
        <div class="form-group">
            <label>SMS 수신 여부</label>
            <select v-model="formData.sms_YN" class="input-box">
            <option value="true">예</option>
            <option value="false">아니오</option>
            </select>
        </div>
        <!-- 버튼 -->
        <div class="button-container">
            <button type="button" class="button-list" @click="goToList">목록</button>
            <button type="submit" class="button-register">수정</button>
            <button type="button" class="button-delete" @click="deleteAdmin">삭제</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  import DailyCheckModule from "@/lib/DailyCheckModule";
  import {sendWorkLog} from "@/lib/logModule";

  export default {
    props: {
        adminData: {
            type: Object,
            required: true,
        },
    },
    data() {
      return {
        formData: {
            uuid:"",
            name: this.adminData.name,
            id: this.adminData.id,
            mobile_no: this.adminData.mobile_no,
            admin_type: this.adminData.admin_type,
            sms_YN: this.adminData.sms_YN,
            password:"********************",
            passwordConfirm:"********************",
        },
        allAdmins: [], // 전체 관리자 데이터
        errors: {
          id: "",
          mobile_no: "",
          duplicate: "",
          password:"",
          passwordConfirm:"",
        },        
        isFormValid: true,
      };
    },
    computed: {
      senderNumber() {
        return this.$store.state.senderNumber; // Vuex state에서 발신 번호 가져오기
      },
        translatedAdminType() {
        const typeMapping = {
            OPERATOR: "운영자",
            DIRECTOR: "원장",
            INSTRUCTOR: "교관",
        };
        return typeMapping[this.formData.admin_type] || this.formData.admin_type;
        },
    },
    mounted() {
        console.log("mount point props adminData:"+this.adminData.uuid);
        this.formData.uuid=this.adminData.uuid;
        this.fetchAllAdmins(); // 전체 관리자 데이터를 로드
        if (this.formData.mobile_no) {
            this.formData.mobile_no = this.formatMobileNo(this.formData.mobile_no); // 초기 포맷팅 적용
        }
    },

    methods: {
      async fetchAllAdmins() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";

          const response = await axios.get("/admins/read", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          this.allAdmins = response.data.filter(
            (admin) => admin.uuid !== this.adminData.uuid
          ); // 수정 중인 관리자를 제외
        
        } catch (error) {
          console.error("관리자 데이터 로드 실패:", error);
          Swal.fire("오류", "관리자 데이터를 불러오는 데 실패했습니다.", "error");
        }
      },
      
      validateDuplicateID() {
        const duplicate = this.allAdmins.find(
          (admin) => admin.id === this.formData.id
        );
        if (duplicate) {
          this.errors.id = "이미 존재하는 관리자 아이디입니다.";
          this.isFormValid = false;
        } else {
          this.errors.id = "";
          this.isFormValid = !this.errors.mobile_no; 
        }
      },
      validatePasswords() {
        // 비밀번호가 입력되지 않았을 경우
        if (!this.formData.password) {
          this.errors.password = "비밀번호를 입력해주세요.";
        } else {
          this.errors.password = "";
        }

        // 비밀번호 확인이 비어 있을 경우
        if (!this.formData.passwordConfirm) {
          this.errors.passwordConfirm = "비밀번호 확인을 입력해주세요.";
        } else if (this.formData.password !== this.formData.passwordConfirm) {
          // 비밀번호와 확인 비밀번호가 일치하지 않을 경우
          this.errors.passwordConfirm = "비밀번호가 일치하지 않습니다.";
        } else {
          this.errors.passwordConfirm = "";
        }
      },
     

      // validateId() {
      //   this.errors.id = this.formData.id
      //       ? ""
      //       : "관리자 아이디를 입력해주세요.";
      // },
      validateMobileNo() {
        const mobileRegex = /^01[0-9]-\d{3,4}-\d{4}$/;

        if (this.errors.mobile_no) {
          return; // 이미 formatMobileNo에서 에러 메시지가 설정된 경우 처리하지 않음
        }

        // 전화번호 형식 검사
        if (!mobileRegex.test(this.formData.mobile_no)) {
          this.errors.mobile_no = "유효한 전화번호 형식이 아닙니다.";
        } else {
          // 입력된 전화번호에서 하이픈 제거
          const formattedMobile = this.formData.mobile_no.replace(/-/g, "");

          // 기존 데이터와 중복 여부 검사 (DB에 저장된 전화번호에서도 하이픈 제거 후 비교)
          const isDuplicate = this.existingAdmins.some(
            (admin) => admin.mobile_no.replace(/-/g, "") === formattedMobile
          );

          if (isDuplicate) {
            this.errors.mobile_no = "중복된 전화번호입니다.";
          } else {
            this.errors.mobile_no = ""; // 유효하면 에러 초기화
          }
        }

        this.updateFormValidity();
      },

      updateFormValidity() {
        this.isFormValid =
          !this.errors.id &&
          !this.errors.mobile_no &&
          !this.errors.instructorNo &&
          !this.errors.password && // 비밀번호 에러 포함
          !this.errors.passwordConfirm; // 비밀번호 확인 에러 포함
      },
      
     handleMobileNoInput(event) {
        const onlyNumbers = event.target.value.replace(/[^0-9]/g, ""); // 숫자만 남김
        if (onlyNumbers.length > 11) {
            //console.log("11 digit overflow!!!!!!");
            event.target.value = this.formatMobileNo(onlyNumbers.slice(0, 11)); // 11자리까지 제한
            this.formData.mobile_no = onlyNumbers.slice(0, 11); // 데이터 모델 업데이트
            return;
        }
        // 에러 초기화 및 포맷 적용
        this.errors.mobile_no = "";
        this.formData.mobile_no = this.formatMobileNo(onlyNumbers); 
        event.target.value = this.formData.mobile_no;
     },
     formatMobileNo(mobileNo) {
        if (!mobileNo) return ""; // 전화번호가 없는 경우 빈 문자열 반환

        // "01"로 시작하지 않으면 에러 처리
        if (!mobileNo.startsWith("01")) {
          this.errors.mobile_no = "전화번호는 01로 시작해야 합니다.";
          return mobileNo; // 기존 입력값 반환
        }

        this.errors.mobile_no = ""; // 유효한 경우 에러 초기화

        // 길이에 따라 포맷 적용
        if (mobileNo.length === 10) {
          return mobileNo.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        } else if (mobileNo.length === 11) {
          return mobileNo.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        } else {
          return mobileNo; // 예상치 못한 형식 그대로 반환
        }
      },


      goToList() {
        this.$router.push({ name: "admin-read" });
      },

      async updateAdmin() {

        // 비밀번호 검증 추가
        this.validatePasswords();
        if (this.errors.password || this.errors.passwordConfirm) {
          Swal.fire("입력 오류", "비밀번호를 확인해주세요.", "error");
          return;
        }
      
        if (!this.isFormValid) {
          Swal.fire("입력 오류", "중복된 데이터를 확인해주세요.", "error");
          return;
        }

        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";

          const payload = { ...this.formData };

          // 패스워드가 변경되지 않았다면 삭제
          if (payload.password === "********************") {
            delete payload.password;
            delete payload.passwordConfirm;
          }else{
            delete payload.passwordConfirm;
          }

          console.log("payload.uuid: "+payload.uuid);

          await axios.put(`/admins/update/${this.adminData.uuid}`, payload, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          await DailyCheckModule.recreateSmsData(this.senderNumber);

          Swal.fire("수정 성공", "관리자가 수정되었습니다.", "success");
          sendWorkLog('100101');  
          this.goToList();
        } catch (error) {
          Swal.fire(
            "수정 실패",
            //error.response?.data?.message || "오류가 발생했습니다.",
            error.message || "오류가 발생했습니다.",
            "error"
          );
        }
    },
      

      async deleteAdmin() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";
  
          if (!token) {
            Swal.fire("인증 실패", "로그인이 필요합니다.", "warning");
            return;
          }
  
          const confirm = await Swal.fire({
            title: "정말 삭제하시겠습니까?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "삭제",
            cancelButtonText: "취소",
          });
  
          if (confirm.isConfirmed) {
            
              // 일반 관리자인 경우 실제 데이터 삭제
              await axios.delete(`/admins/delete/${this.formData.uuid}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              sendWorkLog('100102');  
              await DailyCheckModule.recreateSmsData(this.senderNumber);

              Swal.fire("삭제 완료", "관리자 데이터가 삭제되었습니다.", "success");
            
            this.goToList();
          }
        } catch (error) {
          Swal.fire(
            "삭제 실패",
            error.response?.data?.message || "삭제 중 오류가 발생했습니다.",
            "error"
          );
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* 동일한 스타일 사용 */
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .input-box {
    flex: 1;
    padding: 8px;
    margin-right: 40px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    max-width: 200px;
  }
  
  .button-container {
    margin-top: 60px;
    display: flex;
    gap: 30px;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-register {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-delete {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-list:hover {
    background-color: #e68900;
  }
  
  .button-register:hover {
    background-color: #3e8e41;
  }
  
  .button-delete:hover {
    background-color: #b81004;
  }
  .error {
    color: red; /* 오류를 강조하기 위해 빨간색 사용 */
    font-size: 12px; /* 메시지 크기를 작게 설정하여 레이아웃을 방해하지 않음 */
    margin-top: 4px; /* 입력 필드와의 간격을 약간 둠 */
    font-weight: bold; /* 메시지를 강조 */
    line-height: 1.5; /* 메시지가 읽기 좋도록 줄 간격 설정 */
  }
  </style>
  